import React, { useState } from 'react';
import { GridLoader } from 'react-spinners';
import { FiCopy } from 'react-icons/fi'; // Example using react-icons

function ChatGPTForm() {
    const [CompanyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [consent, setConsent] = useState(false);
    const [responseContent, setResponseContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(responseContent)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000); // Reset confirmation message after 2 seconds
        })
        .catch(err => console.error('Could not copy text: ', err));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!consent) {
            alert('Please provide consent for email communication and marketing messages.');
            return;
        }
        setIsLoading(true); // Start loading
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/';
            // Send company name and email to your BabbageFlask API
            const response = await fetch(`${API_URL}api/submit_form`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ CompanyName: CompanyName, email: email }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log("Full API Response:", responseData); // Log the full response
            setResponseContent(responseData.data || 'No content available');
        } catch (error) {
            console.error('Error:', error);
            setResponseContent(`Error: ${error.message}`);
        }
        setIsLoading(false); // Stop loading after fetch is complete
    };

    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">GDPR Policy Generator</h1>
                    <h3 className="heading">Generate Your GDPR Policy with Ease</h3>
                    <p className="body-text">To get started, simply enter your company name and email address below. At
                        Babbage Law, we prioritize your privacy and the security of your data. Rest assured that your
                        information is used exclusively to tailor the GDPR policy to your specific business needs. </p>
                        <p className="body-text">Our
                            automated system ensures that your data is handled with the highest standards of confidentiality
                        and care. Try our easy-to-use form and safeguard your business today with a bespoke GDPR policy
                        crafted by experts.</p>

                    <div className="cta-section-form-wrapper center">
                        <form onSubmit={handleSubmit} className="form">
                            <div>
                                <input
                                    className="input medium w-input"
                                    maxLength="256"
                                    name="CompanyName"
                                    data-name="Company name"
                                    placeholder="Company name"
                                    type="text"
                                    id="CompanyName"
                                    value={CompanyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    required
                                />
                                <input
                                    className="input medium w-input"
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <div>

                                    <p className="body-text s s-m-b-0">
                                        <input
                                            type="checkbox"
                                            name="consent"
                                            checked={consent}
                                            onChange={(e) => setConsent(e.target.checked)}
                                            required
                                        /> I agree to receive communication and marketing messages.</p>
                                </div>
                                <input
                                    type="submit"
                                    data-wait="Please wait..."
                                    className={`button medium form-button w-button ${isLoading ? 'disabled-button' : ''}`}
                                    value={isLoading ? "Building Contract... This may take up to 2 minutes." : "Create Contract"}
                                    disabled={isLoading}
                                />
                                <p className="body-text s s-m-b-0">
                                    This is a beta service and is offered without obligation or warranty.
                                </p>
                            </div>
                        </form>
                    </div>
                    {isLoading ? (
                        <GridLoader/>
                    ) : (
                        responseContent && (
                            <div>
                                <strong>Response:</strong>
                                <div style={{position: 'relative'}}>
                                    <textarea value={responseContent} readOnly
                                              style={{width: '100%', height: '200px'}}/>
                                    <button onClick={handleCopyToClipboard}
                                            style={{position: 'absolute', right: 0, top: 0}}>
                                        <FiCopy/> {/* Copy Icon */}
                                    </button>
                                </div>
                                {isCopied && <span style={{color: 'green'}}>Copied!</span>}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default ChatGPTForm;
