import {NavLink} from "react-router-dom";
import React from "react";

const Header = () => {
  return (

          <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease"
               data-easing2="ease" role="banner" className="navbar w-nav">
              <div className="container container-navbar">
                  <NavLink  to="/" className="brand-logo w-nav-brand">
                      <img src="images/Babbage---Full---Black.svg" alt="" className="brand-logo-image"/>
                  </NavLink>
                  <nav role="navigation" className="navbar-menu w-nav-menu">
                      <NavLink
                        to="/"
                        className={({ isActive }) => isActive ? "nav-link w--current" : "nav-link"}
                    >
                        Home
                    </NavLink>
                      <div data-hover="false" data-delay="0" className="w-dropdown" aria-controls="w-dropdown-list-0">
                          <div className="nav-link nav-link-dropdown w-dropdown-toggle" id="w-dropdown-toggle-0" >
                              <div className="dropdown-icon w-icon-dropdown-toggle"></div>
                              <div>Free Legal Contracts</div>
                          </div>
                          <nav className="dropdown-menu-navbar w-dropdown-list">
                              <NavLink  to="/GDPR" className="dropdown-menu-item---navbar w-dropdown-link">GDPR Policy </NavLink>
                              <NavLink  to="/EmploymentContract" className="dropdown-menu-item---navbar w-dropdown-link">Employment Contract</NavLink>
                              <NavLink  to="/NDA" className="dropdown-menu-item---navbar w-dropdown-link">NDA</NavLink>
                              <NavLink  to="/MutualNDA" className="dropdown-menu-item---navbar w-dropdown-link">Mutual NDA</NavLink>
                              <NavLink  to="/ServiceAgreement" className="dropdown-menu-item---navbar w-dropdown-link">Service Agreement</NavLink>

                          </nav>
                      </div>
                      <NavLink to="/Library" className={({ isActive }) => isActive ? "nav-link w--current" : "nav-link"}>Law Library</NavLink>
                      <NavLink to="/BabbageLawyer" className={({ isActive }) => isActive ? "nav-link w--current" : "nav-link"}>Babbage Lawyer</NavLink>
                      <NavLink to="/About" className={({ isActive }) => isActive ? "nav-link w--current" : "nav-link"}>About</NavLink>
                      <NavLink to="/Contact" className={({ isActive }) => isActive ? "nav-link w--current" : "nav-link"}>Contact</NavLink>
                      <div className="navbar-buttons">
                          <NavLink to ="/SignupForm" className="button medium w-inline-block">
                              <div className="button-text">Sign-up</div>
                          </NavLink>
                      </div>
                  </nav>
                  <div className="menu-button w-nav-button">
                      <div className="w-icon-nav-menu"></div>
                  </div>
              </div>
          </div>

  );
};

export default Header;