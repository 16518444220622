import {NavLink} from "react-router-dom";
import React from "react";

const CtaSection = () => {
  return (

      <div className="cta-section">
        <div className="container">
          <div className="wrapper-m">
            <div className="cta-box c-b-neutral-100">
              <div className="w-layout-grid grid-cta">
                <div className="cta-section-text-wrapper st-m-b-24">
                  <h2 className="heading l c-t-neutral-10 s-m-b-0">Get started using AI-driven law right away</h2>
                </div>
                <div id="w-node-eafbc070-20cb-8493-0600-2a57c2ac0763-c2ac075b">

                 <NavLink to="/SignupForm" className="button medium w-inline-block">

                    <div className="button-content-wrapper">
                      <div className="button-text">Sign up now</div>
                      <div className="material-icons icon-right">arrow_forward</div>
                    </div>
                 </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default CtaSection;