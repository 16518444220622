import React from 'react';
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ChatGPTForm from './components/ChatGPTForm';
import Privacy from './components/Privacy';
import HomePage from "./components/HomePage"; // Import the Privacy component
import Footer from './components/Footer';
import Header from './components/Header';
import CookiesDisclaimer from "./components/CookiesDisclaimer";
import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import EmploymentContract from "./components/EmploymentContract";
import NDA from "./components/NDA";
import SignupForm from "./components/SignupForm";
import MutualNDAContract from "./components/Mutual_NDA";
import ServiceAgreement from "./components/ServiceAgreement";
import Library from "./components/Library";
import BabbageLawyer from "./components/BabbageLawyer";
import ArticleDetail from "./components/ArticleDetail";

const App = () => {
    return (
        <Router>
            <div className="App">




                <Header />

                <Routes>
                    {/* Define your routes here */}
                    <Route exact path="/" element={<HomePage />} /> {/* Home route */}
                    <Route path="/GDPR" element={<ChatGPTForm />} /> {/* GDPR Builder */}
                    <Route path="/privacy" element={<Privacy />} /> {/* Privacy Policy route */}
                    <Route path="/cookies-disclaimer" element={<CookiesDisclaimer />} /> {/* Cookies Disclaimer*/}
                    <Route path="/About" element={<About />} /> {/* About*/}
                    <Route path="/Contact" element={<Contact />} /> {/* Contact*/}
                    <Route path="/Services" element={<Services />} /> {/* Services*/}
                    <Route path="/EmploymentContract" element={<EmploymentContract />} /> {/* Services*/}
                    <Route path="/NDA" element={<NDA />} /> {/* NDA*/}
                    <Route path="/MutualNDA" element={<MutualNDAContract />} /> {/* MutualNDA*/}
                    <Route path="/SignupForm" element={<SignupForm />} /> {/* Signupform*/}
                    <Route path="/ServiceAgreement" element={<ServiceAgreement />} /> {/* NDA*/}
                    <Route path="/Library" element={<Library />} /> {/* Library*/}
                    <Route path="/BabbageLawyer" element={<BabbageLawyer />} /> {/* BabbageLawyer*/}

                   <Route path="/article/:slug" element={<ArticleDetail />} />   {/* Article Detail route */}
                     {/* ... other routes ... */}
                </Routes>



                <Footer />

                {/* Cookie Consent Component */}
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    cookieName="BabbageCookie"
                    style={{ background: "#2B373B", textAlign: "center" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "5px" }}
                    expires={150}
                >
                    Babbage.law uses cookies to enhance user experience.
                </CookieConsent>

            </div>
        </Router>
    );
};

export default App;
