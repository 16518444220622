import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import './library.css';

const Library = () => {
    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchArticles = async () => {
        const apiUrl = process.env.REACT_APP_FLASK_DB_API;
        if (!apiUrl) {
            setError('API URL is not set');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/articles`);
            console.log('API response:', response.data);
            const liveArticles = response.data.filter(article => article.status === "live");
            console.log('Live articles:', liveArticles);
            setArticles(liveArticles);
            setFilteredArticles(liveArticles);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching articles:", err);
            setError(`Failed to fetch articles: ${err.message}`);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchArticles();
    }, []);

    useEffect(() => {
        const results = articles.filter(article =>
            article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            extractText(article.content).toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredArticles(results);
    }, [searchTerm, articles]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const extractText = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.innerText || "";
    };

    const createExcerpt = (content, maxLength = 100) => {
        const extractedText = extractText(content);
        if (extractedText.length <= maxLength) return extractedText;
        const lastSpace = extractedText.lastIndexOf(' ', maxLength);
        return extractedText.substring(0, lastSpace > 0 ? lastSpace : maxLength) + '...';
    };

    const regenerateSlugs = async () => {
        const apiUrl = process.env.REACT_APP_FLASK_DB_API;
        try {
            await axios.post(`${apiUrl}/articles/generate-missing-slugs`);
            fetchArticles();
        } catch (err) {
            console.error("Error regenerating slugs:", err);
        }
    };

    return (
        <div className="library-section">
            <div className="container">
                <h2 className="library-title">Babbage Law Library</h2>
                <p className="library-description">
                    Please feel free to use the Babbage Law Library. The advice contained herein is offered without indemnity, and you are encouraged to seek advice from a qualified legal professional.
                </p>
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Search articles..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-input"
                    />
                </div>

                <div className="articles-container">
                    {loading ? (
                        <p>Loading articles...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                        <div className="articles-list">
                            {filteredArticles.length > 0 ? (
                                filteredArticles.map((article) => (
                                    <div key={article.id} className="article-item">
                                        <h3 className="article-title">
                                            <Link to={article.slug ? `/article/${article.slug}` : '#'}>
                                                {article.title}
                                            </Link>
                                        </h3>
                                        <p className="article-excerpt">
                                            {createExcerpt(article.content)}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p>No articles found matching your search.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Library;