import React from "react";
import {NavLink} from "react-router-dom";

const FeatureGridSection = () => {
  return (

      <div className="feature-grid-section">
          <div className="container">
              <div className="wrapper-m">
                  <div className="w-layout-grid l-g-3-col">
                      <div className="feature-block centered">
                          <div className="feature-icon-wrapper">
                              <div className="material-icons feature-icon">star</div>
                          </div>
                          <h3 className="heading xs">GDPR Compliance Expertise</h3>
                          <p className="body-text m s-m-0">Navigate the complexities of data protection with our expert
                              GDPR contract services at Babbage & Law. Specialising in GDPR compliance, our tailored
                              contracts help safeguard your business by ensuring data processing meets stringent EU
                              regulations. Our legal experts provide comprehensive support, minimising risk and
                              enhancing your compliance strategy.</p>
                          <NavLink to="/GDPR" className="button medium w-inline-block">
                                <div className="button-text">Build GDPR Policy</div>
                          </NavLink>
                      </div>
                      <div className="feature-block centered">
                          <div className="feature-icon-wrapper">
                              <div className="material-icons feature-icon">star</div>
                          </div>
                          <h3 className="heading xs">Robust NDA Protection</h3>
                          <p className="body-text m s-m-0">Secure your sensitive information with custom Non-Disclosure
                              Agreements (NDAs) from Babbage & Law. Our NDAs are designed to protect your trade secrets
                              and proprietary information during negotiations, partnerships, or any business engagement,
                              ensuring safe and confidential communications.</p>
                                 <NavLink to="/NDA" className="button medium w-inline-block">
                                <div className="button-text">Build NDA</div>
                          </NavLink>
                      </div>
                      <div className="feature-block centered">
                          <div className="feature-icon-wrapper">
                              <div className="material-icons feature-icon">star</div>
                          </div>
                          <h3 className="heading xs">Custom Employment Contracts</h3>
                          <p className="body-text m s-m-0">Strengthen your team with comprehensive Employment Contracts
                              from Babbage & Law. Our contracts cover all essential aspects, including job
                              responsibilities and benefits, aligning with UK employment law to prevent disputes and
                              foster a positive working environment.</p>
                          <NavLink to="/EmploymentContract" className="button medium w-inline-block">
                                <div className="button-text">Draft Employment Contract</div>
                          </NavLink>
                      </div>
                  </div>
              </div>
          </div>
      </div>


  );
};

export default FeatureGridSection;