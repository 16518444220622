import React, { useState } from 'react';
import { GridLoader } from 'react-spinners';
import { FiCopy } from 'react-icons/fi';

function ServiceAgreement() {
  // Initializing state for each required field
    const [contractDetails, setContractDetails] = useState({
        email: '',
        companyName: '',
        marketingConsent: false,
        serviceProviderName: '',
        serviceProviderAddress: '',
        serviceProviderEmail: '',
        serviceProviderPhone: '',
        serviceProviderLegalStatus: '',
        clientName: '',
        clientAddress: '',
        clientEmail: '',
        clientPhone: '',
        clientLegalStatus: '',
        serviceDescription: '',
        serviceOutcomes: '',
        serviceLocation: '',
        paymentTermsAmount: '',
        paymentTermsSchedule: '',
        paymentTermsAdvancePayment: '',
        paymentTermsExpensesReimbursed: '',
        paymentTermsPaymentMethod: '',
        duration_effectiveDate: '',
        duration_endDate: '',
        duration_milestones: '',
        confidentialInfo: '',
        confidentialityExceptions: '',
        liabilityLimitations: '',
        indemnificationProvisions: '',
        terminationTerms: '',
        noticePeriod: '',
        method: '',
        governingLaw: '',
        jurisdiction: '',
        miscellaneous: '',
        signatureDetails: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [responseContent, setResponseContent] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [consent, setConsent] = useState(false);

      // Handling input changes
    const handleChange = (e) => {
      const { name, value, parent } = e.target;
      if (parent) {
        setContractDetails(prev => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [name]: value
          }
        }));
      } else {
        setContractDetails(prev => ({
          ...prev,
          [name]: value
        }));
      }
};



    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(responseContent)
        .then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        })
        .catch(err => console.error('Could not copy text: ', err));
    };

    const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    try {
        const response = await fetch(`${API_URL}/api/service_agreement`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contractDetails),  // Here's the change
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        setResponseContent(responseData.data || 'No content available');
    } catch (error) {
        console.error('Error:', error);
        setResponseContent(`Error: ${error.message}`);
    } finally {
        setIsLoading(false);
    }
};


    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">Service Contract Generator</h1>
                    <form onSubmit={handleSubmit} className="form">
                        <input type="email" name="email" placeholder="Your Email Address"
                               className="input medium w-input" value={contractDetails.email}
                               onChange={handleChange} required/>
                        <input type="text" name="companyName" placeholder="Your Company Name"
                               value={contractDetails.companyName} className="input medium w-input"
                               onChange={handleChange} required/>
                        <input type="text" name="serviceProviderName" placeholder="Service Provider's Legal Name"
                               className="input medium w-input"
                               value={contractDetails.serviceProviderName} onChange={handleChange} required/>
                        <input type="text" name="serviceProviderAddress" placeholder="Service Provider's Address"
                               className="input medium w-input"
                               value={contractDetails.serviceProviderAddress} onChange={handleChange} required/>

                        <input type="email" name="serviceProviderEmail" placeholder="Service Provider's Email Address"
                               className="input medium w-input"
                               value={contractDetails.serviceProviderEmail} onChange={handleChange} required/>

                        <input type="text" name="serviceProviderPhone" placeholder="Service Provider's Phone"
                               className="input medium w-input"
                               value={contractDetails.serviceProviderPhone} onChange={handleChange} required/>
                        <input type="text" name="clientName" placeholder="Client Name"
                               value={contractDetails.clientName} className="input medium w-input"
                               onChange={handleChange} required/>
                        <input type="text" name="clientAddress" placeholder="Client Address"
                               value={contractDetails.clientAddress} className="input medium w-input"
                               onChange={handleChange} required/>
                        <input type="text" name="clientPhone" placeholder="Client Phone Number"
                               value={contractDetails.clientPhone} className="input medium w-input"
                               onChange={handleChange}/>
                        <input type="email" name="clientEmail" placeholder="Client Email"
                               value={contractDetails.clientEmail} className="input medium w-input"
                               onChange={handleChange}/>

                        <textarea name="serviceDescription" placeholder="Description of the Services"
                                  className="input medium w-input"
                                  value={contractDetails.serviceDescription} onChange={handleChange} required/>

                        <input type="text" name="paymentTermsAmount" placeholder="Payment Amount" className="input medium w-input"
                               value={contractDetails.paymentTermsAmount} onChange={handleChange} required/>

                        <input type="text" name="paymentTermsSchedule" placeholder="Payment Schedule"
                               className="input medium w-input"
                               value={contractDetails.paymentTermsSchedule} onChange={handleChange} required/>

                        <input type="text" name="paymentTermsAdvancePayment" placeholder="Payment Terms : Advance Payment"
                               className="input medium w-input"
                               value={contractDetails.paymentTermsAdvancePayment} onChange={handleChange} required/>

                        <input type="text" name="paymentTermsExpensesReimbursed" placeholder="Expenses Reimbursed?"
                               className="input medium w-input"
                               value={contractDetails.paymentTermsExpensesReimbursed} onChange={handleChange} required/>

                        <input type="text" name="paymentTermsPaymentMethod" placeholder="Payment Method"
                               className="input medium w-input"
                               value={contractDetails.paymentTermsPaymentMethod} onChange={handleChange} required/>

                        <label className="form-label">Effective Date:</label>
                        <input type="date" name="duration_effectiveDate" placeholder="Effective Date"
                               className="input medium w-input"
                               value={contractDetails.duration_effectiveDate} onChange={handleChange} required/>


                        <label className="form-label">End Date:</label>
                        <input type="date" name="duration_endDate" placeholder="End Date" className="input medium w-input"
                               value={contractDetails.duration_endDate}
                               onChange={handleChange} required/>

                        <input type="text" name="duration_milestones" placeholder="Milestones"
                               className="input medium w-input"
                               value={contractDetails.duration_milestones} onChange={handleChange}/>

                        <textarea name="confidentialInfo" placeholder="Confidential Information Details"
                                  className="input medium w-input"
                                  value={contractDetails.confidentialInfo}
                                  onChange={handleChange} />
                        <textarea name="confidentialityExceptions" placeholder="Confidential Exceptions"
                                  className="input medium w-input"
                                  value={contractDetails.confidentialityExceptions}
                                  onChange={handleChange} />


                        <textarea name="liabilityLimitations" placeholder="Liability Limitations"
                                  className="input medium w-input"
                                  value={contractDetails.liabilityLimitations}
                                  onChange={handleChange} />

                        <textarea name="indemnificationProvisions" placeholder="Indemnification Provisions"
                                  className="input medium w-input"
                                  value={contractDetails.indemnificationProvisions}
                                  onChange={handleChange} />

                        <input type="text" name="terminationTerms" placeholder="Termination Terms"
                               className="input medium w-input"
                               value={contractDetails.terminationTerms}
                               onChange={handleChange} />

                        <input type="text" name="noticePeriod" placeholder="Notice Period"
                               className="input medium w-input"
                               value={contractDetails.noticePeriod}
                               onChange={handleChange} />

                        <input type="text" name="method" placeholder="Dispute Resolution Method"
                               className="input medium w-input"
                               value={contractDetails.method}
                               onChange={handleChange} />
                        <input type="text" name="governingLaw" placeholder="Governing Law"
                               className="input medium w-input"
                               value={contractDetails.governingLaw}
                               onChange={handleChange} required />
                        <input type="text" name="jurisdiction" placeholder="Jurisdiction"
                               className="input medium w-input"
                               value={contractDetails.jurisdiction}
                              onChange={handleChange} required />

                        <textarea name="miscellaneous" placeholder="Miscellaneous Provisions"
                                  className="input medium w-input"
                                  value={contractDetails.miscellaneous} onChange={handleChange} />

                        <label>
                            <input type="checkbox" name="consent" checked={consent}
                                   onChange={(e) => setConsent(e.target.checked)} required/>
                            I agree to receive communication and marketing messages.
                        </label>

                        <input type="submit" data-wait="Please wait..."
                               className={`button medium form-button w-button ${isLoading ? 'disabled-button' : ''}`}
                               value={isLoading ? "Building Contract... This may take up to 2 minutes." : "Create Contract"}
                               disabled={isLoading}
                        />
                    </form>

                    {isLoading ? (
                        <GridLoader/>
                    ) : (
                        responseContent && (
                            <div>
                                <strong>Response:</strong>
                                <div style={{position: 'relative'}}>
                                    <textarea
                                        value={responseContent}
                                        readOnly
                                        style={{width: '100%', height: '200px'}}
                                    />
                                        <button
                                            onClick={handleCopyToClipboard}
                                            style={{position: 'absolute', right: 0, top: 0}}>
                                            <FiCopy/>
                                        </button>
                                    </div>
                                    {isCopied && <span style={{color: 'green'}}>Copied!</span>}
                                </div>
                            )
                        )}
                    </div>

            </div>
        </div>
);
}

export default ServiceAgreement;
