import React from "react";
import {NavLink} from "react-router-dom";

const Hero = () => {
  return (

           <div className="hero-section">
    <div className="container">
      <div className="wrapper-m">
        <div className="w-layout-grid hero-grid">
          <div className="text-wrapper-l">
            <NavLink to="/SignupForm">
              <div className="tagline">Get Babbage</div>
            </NavLink>
            <h1 className="heading xl">Generate law <span className="c-t-primary-60">faster</span></h1>
            <p className="body-text m">Get the legal advice you need from the best AI models in the industry. Oh, and
              the models in the picture are DALL-E generated too :)</p>
            <p className="body-text m">We won't keep or use your data, except with your permission to communicate with you. Your information will not be shared with any models.
              There is no charge (at the moment) for our beta services, but we do appreciate your feedback. </p>
              <div className="ctas-wrapper">
                <NavLink to="/GDPR" className="button medium w-inline-block">
                  <div className="button-text">Build GDPR Policy</div>
                </NavLink>
                <div className="button-separator"></div>
                <NavLink to="/About" className="button medium secondary w-inline-block">
                  <div className="button-text">Learn more</div>

                </NavLink>
              </div>
          </div>
          <img src="images/teampic.jpeg" alt="Babbage Team pic" className="hero-image" />
        </div>
      </div>
    </div>
  </div>

  );
};

export default Hero;