
const About = () => {



    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">About Babbage</h1>
                    <h2 className="heading l">Welcome to Babbage & Partners – Navigating UK Commercial Law with a Personal Touch</h2>
                    <p className="body-text m">
                        At Babbage & Partners, we believe in the power of personalized legal counsel tailored to the unique needs of each business. As a dynamic team of founders, we are dedicated to offering bespoke legal solutions in the field of UK commercial law.
                    </p>
                    <p className="body-text m">
                        Our journey is about growing alongside our clients. We may be small, but our ambition and commitment to deliver high-quality legal advice is immense. Specializing in a range of commercial law areas, including contracts, intellectual property, and compliance, we offer the agility and personalized attention that only a focused team can provide.
                    </p>
                    <p className="body-text m">
                        We understand the challenges and opportunities that businesses face in today's complex legal environment. Whether you are an emerging startup or an established business seeking nimble and effective legal advice, Babbage & Partners is here to guide you with clarity and a hands-on approach.
                    </p>
                    <p className="body-text m">
                        Our mission is simple: to empower your business through legal excellence and dedicated partnership. We believe in building strong, enduring relationships with our clients, ensuring that we are not just your lawyers but your trusted allies in business.
                    </p>
                    <p className="body-text m">
                        Join us on this journey as we grow and evolve, committed to becoming your go-to legal advisors in the UK commercial law space.
                    </p>
                    <p className="body-text m"><b>
Babbage & Partners – Your Boutique Ally in Commercial Law.</b>
                    </p>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
