import React, { useState, useEffect } from 'react';
import { GridLoader } from "react-spinners";
import { FiCopy } from "react-icons/fi";

const Services = () => {



    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">Babbage AI Legal Services</h1>
                    <h2 className="heading l"> Our Services at Babbage & Partners: Innovating Legal Solutions</h2>


                    <p className="body-text m"><b>1. BabbageGPT: The Future of Contract Generation</b></p>
                    <p className="body-text m">

                        At the forefront of legal technology, BabbageGPT is our state-of-the-art contract generation
                        tool. Harnessing the power of advanced AI, BabbageGPT simplifies and streamlines the contract
                        creation process. Whether you're drafting standard agreements or complex bespoke contracts,
                        BabbageGPT ensures accuracy, efficiency, and compliance with the latest legal standards.
                        Participate in our beta testing phase and experience firsthand how BabbageGPT is setting a new
                        benchmark in legal documentation.</p>
                    <p className={"body-text m"}><b>2. Babbage In-house Counsel: Your Dedicated Legal Partner</b></p>

                    <p className="body-text m">

                        Understanding the diverse legal needs of businesses, we offer Babbage In-house Counsel – a
                        service providing dedicated legal support tailored to your business. Our team of experienced
                        lawyers integrates seamlessly with your operations, offering ongoing legal advice, risk
                        management, and strategic planning. Whether it’s navigating day-to-day legal matters or complex
                        transactions, our in-house counsel ensures you have reliable, expert legal support whenever you
                        need it.

                    </p>
                    <p className={"body-text m"}><b>3. Babbage Private Legal LLMs: Personalised Legal Learning</b></p>

                   <p className="body-text m">

                        Babbage Private Legal LLMs is our innovative learning solution for businesses and legal
                        professionals. These personalized legal learning modules leverage advanced learning technologies
                        to provide tailored educational experiences. Covering various aspects of commercial law, these
                        modules are designed to enhance legal knowledge, keeping you abreast of the latest developments
                        and best practices in your industry. Whether you are looking to upskill your team or deepen your
                        own legal expertise, Babbage Private Legal LLMs offer a flexible and effective learning journey.
                    </p>

                    <p className="body-text m">Join Us at Babbage & Partners</p>
                    <p className="body-text m">

                        At Babbage & Partners, we are more than just a law firm – we are innovators at the intersection
                        of law and technology. Our diverse range of services is designed to meet the evolving needs of
                        modern businesses. We invite you to explore these offerings and discover how we can support your
                        business's legal and learning needs.
                        </p>

                    <p className="body-text m"><b>Empowering Your Business with Cutting-Edge Legal Solutions.</b></p>


                        <div>

                        </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
