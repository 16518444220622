// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-section {
    padding: 2rem 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.library-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-align: left;
}

.library-description {
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: left;
}

.articles-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
}

.article-item {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.article-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.article-excerpt {
    font-size: 0.9rem;
    color: #666;
}

.article-title a {
    color: #333;
    text-decoration: none;
}

.article-title a:hover {
    text-decoration: underline;
}

.search-container {
    margin-bottom: 2rem;
}

.search-input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,.25);
}
`, "",{"version":3,"sources":["webpack://./src/components/library.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,4DAA4D;IAC5D,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,qCAAqC;AACzC;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,eAAe;IACf,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,yCAAyC;AAC7C","sourcesContent":[".library-section {\n    padding: 2rem 0;\n}\n\n.container {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 0 1rem;\n}\n\n.library-title {\n    font-size: 1.8rem;\n    margin-bottom: 1rem;\n    text-align: left;\n}\n\n.library-description {\n    font-size: 1rem;\n    margin-bottom: 2rem;\n    text-align: left;\n}\n\n.articles-list {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n    gap: 2rem;\n}\n\n.article-item {\n    background-color: #f9f9f9;\n    padding: 1rem;\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n}\n\n.article-title {\n    font-size: 1.2rem;\n    margin-bottom: 0.5rem;\n}\n\n.article-excerpt {\n    font-size: 0.9rem;\n    color: #666;\n}\n\n.article-title a {\n    color: #333;\n    text-decoration: none;\n}\n\n.article-title a:hover {\n    text-decoration: underline;\n}\n\n.search-container {\n    margin-bottom: 2rem;\n}\n\n.search-input {\n    width: 100%;\n    padding: 0.5rem;\n    font-size: 1rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.search-input:focus {\n    outline: none;\n    border-color: #007bff;\n    box-shadow: 0 0 0 2px rgba(0,123,255,.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
