// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-detail {
    background-color: #f9f9f9;
    min-height: 100vh;
    padding: 2rem 0;
}

.article-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.article-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
}

.article-meta {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 2rem;
}

.article-date, .article-author {
    margin-right: 1rem;
}

.article-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #444;
    margin-bottom: 1.5rem;
}

.back-link {
    display: inline-block;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.back-link:hover {
    background-color: #0056b3;
}

.loading, .error, .not-found {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    padding: 2rem;
}

.error {
    color: #d32f2f;
}`, "",{"version":3,"sources":["webpack://./src/components/ArticleDetail.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".article-detail {\n    background-color: #f9f9f9;\n    min-height: 100vh;\n    padding: 2rem 0;\n}\n\n.article-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 2rem;\n    background-color: white;\n    box-shadow: 0 0 10px rgba(0,0,0,0.1);\n}\n\n.article-title {\n    font-size: 2.5rem;\n    color: #333;\n    margin-bottom: 1rem;\n}\n\n.article-meta {\n    font-size: 0.9rem;\n    color: #666;\n    margin-bottom: 2rem;\n}\n\n.article-date, .article-author {\n    margin-right: 1rem;\n}\n\n.article-content p {\n    font-size: 1.1rem;\n    line-height: 1.6;\n    color: #444;\n    margin-bottom: 1.5rem;\n}\n\n.back-link {\n    display: inline-block;\n    margin-top: 2rem;\n    padding: 0.5rem 1rem;\n    background-color: #007bff;\n    color: white;\n    text-decoration: none;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n}\n\n.back-link:hover {\n    background-color: #0056b3;\n}\n\n.loading, .error, .not-found {\n    text-align: center;\n    font-size: 1.2rem;\n    color: #666;\n    padding: 2rem;\n}\n\n.error {\n    color: #d32f2f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
