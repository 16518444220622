import React, { useState } from 'react';
import { GridLoader } from 'react-spinners';

function SignupForm() {
    const [CompanyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [consent, setConsent] = useState(false);
    const [responseContent, setResponseContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!consent) {
            alert('Please provide consent for email communication and marketing messages.');
            return;
        }
        setIsLoading(true); // Start loading
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
            const response = await fetch(`${API_URL}/api/signup_form`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ CompanyName: CompanyName, email: email }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log("Full API Response:", responseData);
            setResponseContent(responseData.message || 'You have successfully signed up!');

            setCompanyName('');
            setEmail('');
            setConsent(false);
        } catch (error) {
            console.error('Error:', error);
            setResponseContent(`Error: ${error.message}`);
        }
        setIsLoading(false);
    };

    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">Babbage Signup</h1>
                    <p className="body-text m">
                        Signup for the early access to Babbage AI legal services.</p>

                    <div className="cta-section-form-wrapper center">
                        <form onSubmit={handleSubmit} className="form">
                            <div>
                                <input
                                    className="input medium w-input"
                                    maxLength="256"
                                    name="CompanyName"
                                    data-name="Company name"
                                    placeholder="Company name"
                                    type="text"
                                    id="CompanyName"
                                    value={CompanyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    required
                                />
                                <input
                                    className="input medium w-input"
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <div>
                                    <p className="body-text s s-m-b-0">
                                        <input
                                        type="checkbox"
                                        name="consent"
                                        checked={consent}
                                        onChange={(e) => setConsent(e.target.checked)}
                                        required
                                    />  I agree to receive communication and marketing messages.</p>
                                </div>
                                <input
                                    type="submit"
                                    data-wait="Please wait..."
                                    className="button medium form-button w-button"
                                    value="Signup for early access"
                                />
                            </div>
                        </form>
                    </div>
                    {isLoading ? (
                        <GridLoader/>
                    ) : (
                        responseContent && (
                            <div className="response-content">
                                <strong>Response:</strong>
                                <div>{responseContent}</div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default SignupForm;
