import React from 'react';
import { NavLink } from 'react-router-dom';



const Footer = () => {
  return (

      <div className="App">
               <footer className="footer dark">
                   <div className="container">
                       <div className="w-layout-grid footer-grid-2">
                           <div id="w-node-_6377f0eb-4e7a-4c95-b23b-e9c8c55c265f-c55c265c" className="st-m-b-24">
                               <NavLink  to="/" className="footer-logo-2 w-nav-brand">
                                   <img
                                       src="images/Babbage---Full---White.svg"
                                       alt="" className="brand-logo-image"/>
                               </NavLink>
                               <ul role="list" className="footer-address s-m-b-24 w-list-unstyled">
                                   <li className="vertical-menu-list-item color-inverse">
                                       <a href="mailto:info@babbage.law" className="vertical-menu-link color-inverse">
                                           info@babbage.law
                                           <br/>
                                       </a>
                                   </li>
                               </ul>
                           </div>
                           <div>
                               <div className="vertical-menu-title color-inverse">Company</div>
                               <ul role="list" className="w-list-unstyled">
                                   <li className="vertical-menu-list-item">
                                       <NavLink  to="/" className="vertical-menu-link color-inverse">Home</NavLink>
                                   </li>
                                   <li className="vertical-menu-list-item">
                                       <NavLink  to="/About" className="vertical-menu-link color-inverse" >About Us</NavLink>
                                   </li>
                                   <li className="vertical-menu-list-item">
                                        <NavLink  to="/Contact" className="vertical-menu-link color-inverse">Contact</NavLink>
                                   </li>

                               </ul>
                           </div>
                           <div>
                               <div className="vertical-menu-title color-inverse">Legal</div>
                               <ul role="list" className="w-list-unstyled">
                                   <li className="vertical-menu-list-item color-inverse">
                                        <NavLink  to="/privacy" className="vertical-menu-link color-inverse" > Privacy Policy </NavLink>
                                   </li>
                                   <li className="vertical-menu-list-item color-inverse">
                                       <NavLink  to="/cookies-disclaimer" className="vertical-menu-link color-inverse"> Cookies/Disclaimer </NavLink>
                                   </li>


                               </ul>
                           </div>
                           <div>
                               <div className="vertical-menu-title color-inverse">Services</div>
                               <ul role="list" className="w-list-unstyled">
                                   <li className="vertical-menu-list-item color-inverse">
                                      <NavLink  to="/Services" className="vertical-menu-link color-inverse">Babbage GPT</NavLink>
                                   </li>
                                   <li className="vertical-menu-list-item color-inverse">
                                       <NavLink  to="/Services" className="vertical-menu-link color-inverse">Babbage In-house counsel</NavLink>
                                   </li>
                                   <li className="vertical-menu-list-item color-inverse">
                                       <NavLink  to="/Services" className="vertical-menu-link color-inverse">Private Legal LLMs</NavLink>
                                   </li>

                               </ul>
                           </div>
                       </div>
                       <div className="footer-bottom color-inverse">
                           <div className="st-m-b-24">
                               ©
                               <span id="year">x</span>
                               &nbsp;Babbage Law. All rights reserved.
                           </div>
                           <div>

                               <a href="/cookies-disclaimer" className="vertical-menu-link color-inverse">Cookies</a>
                           </div>
                       </div>
                   </div>

                </footer>

         </div>
        );
       };

export default Footer;