import React, { useState } from 'react';
import { GridLoader } from 'react-spinners';
import { FiCopy } from 'react-icons/fi';

function NDAContract() {
    const [formData, setFormData] = useState({
        email: '',
        companyName: '',
        disclosingParty: '',
        receivingParty: '',
        confidentialInformation: '',
        agreementDuration: '',
        additionalClauses: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [responseContent, setResponseContent] = useState('');
    const [isCopied, setIsCopied] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(responseContent)
        .then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        })
        .catch(err => console.error('Could not copy text: ', err));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
        try {
            const response = await fetch(`${API_URL}/api/generate_nda`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            setResponseContent(responseData.data || 'No content available');
        } catch (error) {
            console.error('Error:', error);
            setResponseContent(`Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">NDA Contract Generator</h1>
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            className="input medium w-input"
                            type="email"
                            name="email"
                            placeholder="Your email address"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className="input medium w-input"
                            type="text"
                            name="companyName"
                            placeholder="Your Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className="input medium w-input"
                            type="text"
                            name="disclosingParty"
                            placeholder="Disclosing Party"
                            value={formData.disclosingParty}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className="input medium w-input"
                            type="text"
                            name="receivingParty"
                            placeholder="Receiving Party"
                            value={formData.receivingParty}
                            onChange={handleChange}
                            required
                        />
                        <textarea
                            className="input medium w-input"
                            name="confidentialInformation"
                            placeholder="Confidential Information"
                            value={formData.confidentialInformation}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className="input medium w-input"
                            type="text"
                            name="agreementDuration"
                            placeholder="Agreement Duration"
                            value={formData.agreementDuration}
                            onChange={handleChange}
                            required
                        />
                        <textarea
                            className="input medium w-input"
                            name="additionalClauses"
                            placeholder="Additional Clauses (Optional)"
                            value={formData.additionalClauses}
                            onChange={handleChange}
                        />
                        <input
                            type="submit"
                            data-wait="Please wait..."
                            className={`button medium form-button w-button ${isLoading ? 'disabled-button' : ''}`}
                            value={isLoading ? "Building Contract... This may take up to 2 minutes." : "Create Contract"}
                            disabled={isLoading}
                        />
                    </form>

                    {isLoading ? (
                        <GridLoader/>
                    ) : (
                        responseContent && (
                            <div>
                                <strong>Response:</strong>
                                <div style={{position: 'relative'}}>
                                    <textarea
                                        value={responseContent}
                                        readOnly
                                        style={{width: '100%', height: '200px'}}
                                    />
                                    <button
                                        onClick={handleCopyToClipboard}
                                        style={{position: 'absolute', right: 0, top: 0}}>
                                        <FiCopy/>
                                    </button>
                                </div>
                                {isCopied && <span style={{color: 'green'}}>Copied!</span>}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default NDAContract;
