import React, { useState, useEffect } from 'react';


const CookiesDisclaimer = () => {
    const [CookiesDisclaimer, setCookiesDisclaimer] = useState('');

    useEffect(() => {
        fetch('/content/cookies-disclaimer.txt')
            .then(response => response.text())
            .then(text => setCookiesDisclaimer(text));
    }, []);

    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">Babbage Cookies and General Disclaimers</h1>


                    <div>
                        <textarea value={CookiesDisclaimer} readOnly style={{width: '100%', height: '200px'}}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesDisclaimer;
