import React, { useState } from 'react';
import { GridLoader } from 'react-spinners';
import { FiCopy } from 'react-icons/fi';

function EmploymentContract() {
    const [formData, setFormData] = useState({
        email: '',
        employerName: '',
        employerAddress: '',
        employerRegNumber: '',
        employeeName: '',
        employeeAddress: '',
        jobTitle: '',
        jobDescription: '',
        startDate: '',
        endDate: '',
        workLocation: '',
        workingHours: '',
        salary: '',
        payFrequency: '',
        holidayEntitlement: '',
        sicknessPolicy: '',
        pensionDetails: '',
        noticePeriod: '',
        confidentialityClause: false,
        nonCompetitionClause: false,
        additionalBenefits: '',
        governingLaw: 'United Kingdom'
    });

    const [isLoading, setIsLoading] = useState(false);
    const [responseContent, setResponseContent] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [consent, setConsent] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(responseContent)
        .then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Reset confirmation message after 2 seconds
        })
        .catch(err => console.error('Could not copy text: ', err));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!consent) {
            alert('Please provide consent for email communication and marketing messages.');
            return;
        }
        setIsLoading(true); // Start loading
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
            const response = await fetch(`${API_URL}/api/generate_employment_contract`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log("Full API Response:", responseData);
            setResponseContent(responseData.data || 'No content available');
        } catch (error) {
            console.error('Error:', error);
            setResponseContent(`Error: ${error.message}`);
        }
        setIsLoading(false); // Stop loading after fetch is complete
    };

    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">Employment Contract Generator</h1>
                    <p className="body-text m">
                        Welcome to Babbage.Law. Please fill out the form to generate a UK Employment Contract.
                    </p>
                    <div className="cta-section-form-wrapper center">
                        <form onSubmit={handleSubmit} className="form">
                            <input className="input medium w-input" type="text" name="email"
                                   placeholder="Your email address" value={formData.email} onChange={handleChange}
                                   required/>
                            <input className="input medium w-input" type="text" name="employerName"
                                   placeholder="Employer Name" value={formData.employerName} onChange={handleChange}
                                   required/>
                            <input className="input medium w-input" type="text" name="employerAddress"
                                   placeholder="Employer Address" value={formData.employerAddress}
                                   onChange={handleChange} required/>
                            <input className="input medium w-input" type="text" name="employerRegNumber"
                                   placeholder="Employer Registration Number" value={formData.employerRegNumber}
                                   onChange={handleChange}/>
                            <input className="input medium w-input" type="text" name="employeeName"
                                   placeholder="Employee Name" value={formData.employeeName} onChange={handleChange}
                                   required/>
                            <input className="input medium w-input" type="text" name="employeeAddress"
                                   placeholder="Employee Address" value={formData.employeeAddress}
                                   onChange={handleChange} required/>
                            <input className="input medium w-input" type="text" name="jobTitle" placeholder="Job Title"
                                   value={formData.jobTitle} onChange={handleChange} required/>
                            <textarea className="input medium w-input" name="jobDescription"
                                      placeholder="Job Description" value={formData.jobDescription}
                                      onChange={handleChange} required/>
                            <div>
                                <label className="form-label">Start Date:</label>
                                <input
                                    className="input medium w-input"
                                    type="date"
                                    name="startDate"
                                    placeholder="Start Date"
                                    value={formData.startDate}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            {/* End Date Input */}
                            <div>
                                <label className="form-label">End Date:</label>
                                <input
                                    className="input medium w-input"
                                    type="date"
                                    name="endDate"
                                    placeholder="End Date (optional)"
                                    value={formData.endDate}
                                    onChange={handleChange}
                                />
                            </div>


                            <input className="input medium w-input" type="text" name="workLocation"
                                   placeholder="Work Location" value={formData.workLocation} onChange={handleChange}
                                   required/>
                            <input className="input medium w-input" type="text" name="workingHours"
                                   placeholder="Working Hours" value={formData.workingHours} onChange={handleChange}
                                   required/>
                            <input className="input medium w-input" type="text" name="salary" placeholder="Salary"
                                   value={formData.salary} onChange={handleChange} required/>
                            <input className="input medium w-input" type="text" name="payFrequency"
                                   placeholder="Pay Frequency" value={formData.payFrequency} onChange={handleChange}
                                   required/>
                            <input className="input medium w-input" type="text" name="holidayEntitlement"
                                   placeholder="Holiday Entitlement" value={formData.holidayEntitlement}
                                   onChange={handleChange} required/>
                            <input className="input medium w-input" type="text" name="sicknessPolicy"
                                   placeholder="Sickness Policy" value={formData.sicknessPolicy}
                                   onChange={handleChange}/>
                            <input className="input medium w-input" type="text" name="pensionDetails"
                                   placeholder="Pension Details" value={formData.pensionDetails}
                                   onChange={handleChange}/>
                            <input className="input medium w-input" type="text" name="noticePeriod"
                                   placeholder="Notice Period" value={formData.noticePeriod} onChange={handleChange}
                                   required/>
                            <label>
                                <input type="checkbox" name="confidentialityClause"
                                       checked={formData.confidentialityClause} onChange={handleChange}/>
                                Confidentiality Clause
                            </label>
                            <label>
                                <input type="checkbox" name="nonCompetitionClause"
                                       checked={formData.nonCompetitionClause} onChange={handleChange}/>
                                Non-Competition Clause
                            </label>
                            <input className="input medium w-input" type="text" name="additionalBenefits"
                                   placeholder="Additional Benefits" value={formData.additionalBenefits}
                                   onChange={handleChange}/>
                            <label>
                                Governing Law: <strong>{formData.governingLaw}</strong>
                            </label>
                            <label>
                                <input type="checkbox" name="consent" checked={consent}
                                       onChange={(e) => setConsent(e.target.checked)} required/>
                                I agree to receive communication and marketing messages.
                            </label>
                            <input
                                type="submit"
                                data-wait="Please wait..."
                                className={`button medium form-button w-button ${isLoading ? 'disabled-button' : ''}`}
                                value={isLoading ? "Building Contract... This may take up to 2 minutes." : "Create Contract"}
                                disabled={isLoading}
                            />
                        </form>
                    </div>
                    {isLoading ? (
                        <GridLoader/>
                    ) : (
                        responseContent && (
                            <div>
                                <strong>Response:</strong>
                                <div style={{position: 'relative'}}>
                                    <textarea value={responseContent} readOnly
                                              style={{width: '100%', height: '200px'}}/>
                                    <button onClick={handleCopyToClipboard}
                                            style={{position: 'absolute', right: 0, top: 0}}>
                                        <FiCopy/>
                                    </button>
                                </div>
                                {isCopied && <span style={{color: 'green'}}>Copied!</span>}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmploymentContract;
