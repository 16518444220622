
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";

export default function Contact() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onTouched",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState(false);

  // Please update the Access Key in the .env
  const apiKey = process.env.REACT_APP_PUBLIC_ACCESS_KEY || "YOUR_ACCESS_KEY_HERE";

  const { submit: onSubmit } = useWeb3Forms({
    access_key: apiKey,
    settings: {
      from_name: "Babbage.Law",
      subject: "New Contact Message from your Babbage.Law",
    },
    onSuccess: (msg, data) => {
      setIsSuccess(true);
      setMessage(msg);
      reset();
    },
    onError: (msg, data) => {
      setIsSuccess(false);
      setMessage(msg);
    },
  });

  return (
      <div className="cta-section">
        <div className="container">
          <div className="cta-wrapper">
            <h1 className="heading xl">Contact Us</h1>
            <p className="body-text m">Please fill out the form below to get in touch.</p>

            <div className="cta-section-form-wrapper center">
              <form onSubmit={handleSubmit(onSubmit)} className="form">
                {/* Full Name Input */}
                <input
                    type="text"
                    placeholder="Full Name"
                    className="input medium w-input"
                    {...register("name", { /* ... */})}
                />
                {/* Error Message for Full Name */}
                {errors.name && <small className="error-message">{errors.name.message}</small>}

                {/* Email Input */}
                <input
                    type="email"
                    placeholder="Email Address"
                    className="input medium w-input"
                    {...register("email", { /* ... */})}
                />
                {/* Error Message for Email */}
                {errors.email && <small className="error-message">{errors.email.message}</small>}

                {/* Message Textarea */}
                <textarea
                    placeholder="Your Message"
                    className="input medium w-input"
                    {...register("message", { /* ... */})}
                />
                {/* Error Message for Message */}
                {errors.message && <small className="error-message">{errors.message.message}</small>}

                {/* Submit Button */}
                <button
                    type="submit"
                    className="button medium form-button w-button"
                >
                  Send Message
                </button>
              </form>
            </div>

            {/* Success or Error Messages */}
            {isSubmitSuccessful && (isSuccess ? (
                <div className="success-message">{message || "Success. Message sent successfully"}</div>
            ) : (
                <div className="error-message">{message || "Something went wrong. Please try later."}</div>
            ))}
          </div>
        </div>
      </div>
  );
}


