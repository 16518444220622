import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import './ArticleDetail.css';

const LoadingSkeleton = () => (
    <div className="article-detail loading">
        <div className="skeleton-title"></div>
        <div className="skeleton-meta"></div>
        <div className="skeleton-image"></div>
        <div className="skeleton-content"></div>
    </div>
);

const ArticleDetail = () => {
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        const fetchArticle = async () => {
            const apiUrl = process.env.REACT_APP_FLASK_DB_API;

            if (!apiUrl) {
                setError('API URL is not set');
                setLoading(false);
                return;
            }

            if (!slug) {
                setError('No article slug provided');
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`${apiUrl}/articles/${slug}`);
                setArticle(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching article:", err);
                setError(`Failed to fetch article: ${err.message}`);
                setLoading(false);
            }
        };

        fetchArticle();
    }, [slug]);

    if (loading) return <LoadingSkeleton />;
    if (error) return <div className="article-detail error">{error}</div>;
    if (!article) return <div className="article-detail not-found">No article found.</div>;

    const articleUrl = `https://www.babbage.law/article/${article.slug}`;

    return (
        <>
            <Helmet>
                <title>{`${article.title} | Babbage.law`}</title>
                <meta name="description" content={article.meta_description} />
                <link rel="canonical" href={articleUrl} />
                <meta property="og:title" content={`${article.title} | Babbage.law`} />
                <meta property="og:description" content={article.meta_description} />
                <meta property="og:image" content={article.featured_image} />
                <meta property="og:url" content={articleUrl} />
                <meta property="og:type" content="article" />
                <meta name="twitter:card" content="summary_large_image" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "headline": article.title,
                        "datePublished": article.created_at,
                        "dateModified": article.updated_at,
                        "author": {
                            "@type": "Person",
                            "name": article.author
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Babbage.law",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://www.babbage.law/images/Babbage---Full---Black.svg"  // Replace with your actual logo URL
                            }
                        },
                        "image": article.featured_image,
                        "url": articleUrl,
                        "description": article.meta_description
                    })}
                </script>
            </Helmet>
            <article className="article-detail">
                <div className="article-container">
                    <h1 className="article-title">{article.title}</h1>
                    <div className="article-meta">
                        <time className="article-date" dateTime={article.created_at}>
                            {new Date(article.created_at).toLocaleDateString()}
                        </time>
                        {article.author && <span className="article-author">By {article.author}</span>}
                        {article.category && <span className="article-category">Category: {article.category}</span>}
                    </div>
                    {article.featured_image && (
                        <img
                            src={article.featured_image}
                            alt={article.title}
                            className="featured-image"
                            loading="lazy"
                        />
                    )}
                    <div
                        className="article-content"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}
                    />
                    {article.keywords && <div className="article-keywords">Keywords: {article.keywords}</div>}
                    <Link to="/Library" className="back-link">Back to Library</Link>
                </div>
            </article>
        </>
    );
};

export default ArticleDetail;