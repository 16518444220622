import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GridLoader } from 'react-spinners';
import './chat.css';

function BabbageLawyer() {
  const [query, setQuery] = useState('');
  const [file, setFile] = useState(null); // State for file upload
  const [messages, setMessages] = useState([]); // Chat history
  const [loading, setLoading] = useState(false);
  const chatBoxRef = useRef(null); // For auto-scroll

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!query.trim() && !file) return; // Ensure either a query or a file is provided

    setLoading(true);

    try {
      if (file) {
        // If a file is being uploaded
        const formData = new FormData();
        formData.append('file', file);

        // Upload file to the Flask backend
        const response = await axios.post(`${API_URL}/upload_contract`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Add file upload message to chat
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'user', content: `Uploaded file: ${file.name}` },
          { role: 'solicitor', content: `Contract Analysis: ${response.data.analysis}` },
        ]);

        setFile(null); // Clear the file input after upload
      } else if (query.trim()) {
        // If a text query is being submitted
        const response = await axios.post(`${API_URL}/chat`, { message: query.trim() });

        // Add user query and solicitor response to chat history
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'user', content: query.trim() },
          { role: 'solicitor', content: response.data.response },
        ]);

        setQuery(''); // Clear the text input field after submission
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'solicitor', content: 'There was an error processing your request. Please try again.' },
      ]);
    }

    setLoading(false);
  };

  // Automatically scroll to the bottom of the chat when new messages are added
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h1 className="chat-title">Talk with a Virtual Babbage Lawyer</h1>
        <p className="chat-description">
          Welcome to the Babbage virtual lawyer beta. Please do not rely on any legal advice provided, as this is for demonstration purposes only.
        </p>
        <p className="chat-description">
          Upload a contract, and ask Babbage to analyse it.
        </p>
      </div>

      {/* Chat history box */}
      <div className="chat-box" ref={chatBoxRef}>
        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.role}`}>
            <div className="message-content">{msg.content}</div>
          </div>
        ))}
        {loading && (
          <div className="chat-message solicitor">
            <div className="message-content">
              <GridLoader size={10} color="#36D7B7" />
            </div>
          </div>
        )}
      </div>

      {/* Input form for query and file upload */}
      <form className="chat-input-form" onSubmit={handleSubmit}>
        {/* Chat input box */}
        <div className="input-group">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter your legal query..."
            className="chat-input"
            disabled={loading} // Disable input if file is being uploaded
          />
        </div>

        {/* File upload input */}
        <div className="input-group">
          <input
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            className="file-input"
            disabled={loading} // Disable file input if query is being submitted
          />
        </div>

        {/* Submit button */}
        <div className="input-group">
          <button type="submit" className="chat-submit-btn" disabled={loading}>
            {loading ? '...' : file ? 'Upload File' : 'Send'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default BabbageLawyer;
