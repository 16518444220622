// Homepage.js

import React from 'react';
import Hero from "./Hero";
import FeatureGridSection from "./FeatureGridSection";
import CtaSection from "./CtaSection";

const HomePage = () => {
    return (
        <>
      <Hero />
        <FeatureGridSection />
            <CtaSection />
</>


    );
};

export default HomePage;