import React, { useState, useEffect } from 'react';
import { GridLoader } from "react-spinners";
import { FiCopy } from "react-icons/fi";

const Privacy = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState('');

    useEffect(() => {
        fetch('/content/privacy-policy.txt')
            .then(response => response.text())
            .then(text => setPrivacyPolicy(text));
    }, []);

    return (
        <div className="cta-section">
            <div className="container">
                <div className="cta-wrapper">
                    <h1 className="heading xl">Babbage Privacy Policy</h1>
                    <p className="body-text m">
                        Welcome to Babbage.Law. </p>
                    <p className="body-text m"> Please find Babbage's privacy policy below:
                    </p>
                    <div>
                        <textarea value={privacyPolicy} readOnly style={{width: '100%', height: '200px'}}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
